<template>
    <user-dashboard-layout :loading="! tag.id">
        <v-container>
            <div v-if="tag.videos && tag.videos.length > 0">
                <h2>Videos</h2>
                <v-row>
                    <v-col  sm="12" md="6" xl="2"  v-for="(video, i) in tag.videos" :key="i">
                        <video-card :video="video"/>
                    </v-col>
                </v-row>
            </div>
            <div v-if="tag.socialGraphics && tag.socialGraphics.length > 0">
                <h2>Social Graphics</h2>
                <v-row>
                    <v-col  sm="12" md="6" xl="3"  v-for="(socialGraphic, i) in tag.socialGraphics" :key="i">
                        <social-graphic-card :social-graphic="socialGraphic"/>
                    </v-col>
                </v-row>
            </div>
            <div v-if="tag.infographics && tag.infographics.length > 0">
                <h2>Infographics</h2>
                <v-row>
                    <v-col  sm="12" md="6" xl="3"  v-for="(infographic, i) in tag.infographics" :key="i">
                        <infographic-card :inforgraphic="infographic"/>
                    </v-col>
                </v-row>
            </div >
            <div v-if="tag.guides && tag.guides.length > 0">
                <h2>Guides</h2>
                <v-row>
                    <v-col  sm="12" md="6" xl="3"  v-for="(guide, i) in tag.guides" :key="i">
                        <guide-card :guide="guide"/>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import Tag from "@/models/Tag";
import SocialGraphicCard from "@/components/cards/SocialGraphicCard";
import VideoCard from "@/components/cards/VideoCard";
import InfographicCard from "@/components/cards/InfographicCard";
import GuideCard from "@/components/cards/GuideCard";

export default {
    name: "Show",
    components: {UserDashboardLayout, SocialGraphicCard, VideoCard, InfographicCard, GuideCard},
    data() {
        return {
            tag: {
                socialGraphics: [],
                videos: [],
                infographics: [],
                guides: [],
            },
        }
    },
    async mounted() {
        const tagId = this.$route.params.id
        this.tag = await Tag.include(['videos', 'socialGraphics', 'infographics', 'guides']).find(tagId)
    }
}
</script>

<style scoped>

</style>
